@import '../mixin/_utility';
#hero-search-form {
  padding: 36px 80px;
  font-size: 14px;
  line-height: 1.5;
}
.hero-search-form-row+.hero-search-form-row {
  margin-top: 14px;
}
.hero-search-form-row-inner {
  min-height: 51px;
  position: relative;
}
@media screen and (max-width: 767px) {
  #hero-search-form {
    padding: 24px 20px; padding: 6.15384vw 5.12820vw;
    font-size: 14px; font-size: 3.58974vw;
  }
  .hero-search-form-row+.hero-search-form-row {
    margin-top: 12px; margin-top: 3.07692vw;
  }
  .hero-search-form-row-inner {
    min-height: 56px; min-height: calc(13.84615vw + 2px);
    position: relative;
  }
}
.hero-search-form-input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: #fff;
  border: solid 1px #C6C6C6;
  border-radius: 3px;
  overflow: hidden;
  -webkit-transition: border .3s;
  transition: border .3s;
}
.hero-search-form-input:hover {
  border-color: #A6A6A6;
}
.hero-search-form-input.active {
  border-color: #00005C;
  z-index: 2;
  overflow: initial;
}

@media screen and (max-width: 767px) {
  .hero-search-form-input {
    border-radius: 3px; border-radius: 1.28205vw;
  }
}



.hero-search-form-input-switcher {
  position: relative;
}
.hero-search-form-input-switcher-opener {
  display: flex;
  align-items: center;
  min-height: 51px;
}
.hero-search-form-input-switcher-opener-row {
  box-sizing: border-box;
  position: relative;
  color: #747474;
  padding: 15px;
  flex-basis: 50%;
  flex-grow: 2;
  text-align: left;
}
.hero-search-form-input-switcher-opener-row.icon {
  padding-left: 42px;
}
.hero-search-form-input-switcher-opener-row.icon::before {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  margin: auto 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.hero-search-form-input-switcher-opener-row.icon-location::before {
  background-image: url(../../img/icon/icon_location.svg);
}
.hero-search-form-input-switcher-opener-row.icon-people::before {
  background-image: url(../../img/icon/icon_people.svg);
}
.hero-search-form-input-switcher-opener-row.icon-calendar::before {
  background-image: url(../../img/icon/icon_calendar.svg);
}
.hero-search-form-input-switcher-opener-row.icon-size::before {
  background-image: url(../../img/icon/icon_size.svg);
}
.hero-search-form-input-switcher-opener-row+.hero-search-form-input-switcher-opener-row::before {
  content: "";
  display: block;
  width: 1px;
  height: 60%;
  background-color: #C6C6C6;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
}

@media screen and (max-width: 767px) {
  .hero-search-form-input-switcher-opener {
    min-height: 56px; min-height: calc(14.35897vw - 2px);
    min-height: calc(13.84615vw + 2px);
  }
  .hero-search-form-input-switcher-opener-row {
    padding: 15px; padding: 3.84615vw;
  }
  .hero-search-form-input-switcher-opener-row.icon {
    padding-left: 42px; padding-left: 10.76923vw;
  }
  .hero-search-form-input-switcher-opener-row.icon::before {
    width: 30px; width: 7.69230vw;
    height: 30px; height: 7.69230vw;
    left: 10px; left: 2.56410vw;
  }
}



.hero-search-form-input-switcher-opener-row-default,
.hero-search-form-input-switcher-opener-row-value {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  margin: auto 0;
  padding: 5px 0;
  align-items: center;
  min-width: 0;
  width: calc(100% - 30px);
  font-size: 14px;
  line-height: 1.5;
}
.hero-search-form-input-switcher-opener-row.icon .hero-search-form-input-switcher-opener-row-default,
.hero-search-form-input-switcher-opener-row.icon .hero-search-form-input-switcher-opener-row-value {
  width: calc(100% - 90px);
  left: 42px;
}
.hero-search-form-input-switcher-opener-row-default {
  color: #747474;
}
.hero-search-form-input-switcher-opener-row-value {
  opacity: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hero-search-form-input-switcher-opener-row.active .hero-search-form-input-switcher-opener-row-default {
  opacity: 0;
}
.hero-search-form-input-switcher-opener-row.active .hero-search-form-input-switcher-opener-row-value {
  opacity: 1;
}
.hero-search-form-input-switcher-opener-row-value .search-param {
  display: inline-block;
  background-color: #F0F0F0;
  padding: 5px 10px;
  border-radius: 16px;
  margin-top: -5px;
}
.hero-search-form-input-switcher-opener-row-value .search-param+.search-param {
  margin-left: 6px;
}

@media screen and (max-width: 767px) {
  .hero-search-form-input-switcher-opener-row-default,
  .hero-search-form-input-switcher-opener-row-value {
    left: 8px; left: 2.05128vw;
    padding: 5px 0; padding: 1.28205vw 0;
    width: calc(100% - 16px); width: calc(100% - 4.10256vw);
    font-size: 14px; font-size: 3.58974vw;
  }
  .hero-search-form-input-switcher-opener-row.icon .hero-search-form-input-switcher-opener-row-default,
  .hero-search-form-input-switcher-opener-row.icon .hero-search-form-input-switcher-opener-row-value {
    width: calc(100% - 50px); width: calc(100% - 12.82051vw);
    left: 42px; left: 10.76923vw;
  }
  .hero-search-form-input-switcher-opener-row-value .search-param {
    padding: 5px 10px; padding: 1.28205vw 2.56410vw;
    border-radius: 16px; border-radius: 4.10256vw;
    margin-top: -5px; margin-top: -1.28205vw;
  }
  .hero-search-form-input-switcher-opener-row-value .search-param+.search-param {
    margin-left: 6px; margin-left: 1.53846vw;
  }
}

.hero-search-form-input-switcher .hero-search-form-input-switcher-closer {
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 53px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 0;
  padding: 0;
  color: #00005C;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  opacity: 0;
  pointer-events: none;
}
.active .hero-search-form-input-switcher .hero-search-form-input-switcher-closer {
  opacity: 1;
}
.hero-search-form-input-switcher .hero-search-form-input-switcher-closer > span {
  font-size: 10px;
  line-height: 1;
  margin-top: 13px;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}
.hero-search-form-input-switcher .hero-search-form-input-switcher-closer::before {
  content: "";
  display: block;
  width: 12px;
  height: 6px;
  background: url(../../img/icon/icon_arrow-top_blue.svg) center no-repeat;
  background-size: contain;
  position: absolute;
  top: 17px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .hero-search-form-input-switcher .hero-search-form-input-switcher-closer {
    width: 53px; width: 13.58974vw;
  }
  .hero-search-form-input-switcher .hero-search-form-input-switcher-closer > span {
    font-size: 10px; font-size: 2.56410vw;
    margin-top: 13px; margin-top: 3.33333vw;
  }
  .hero-search-form-input-switcher .hero-search-form-input-switcher-closer::before {
    width: 12px; width: 3.07692vw;
    height: 6px; height: 1.53846vw;
    top: 17px; top: 4.35897vw;
  }
}

.hero-search-form-row-notes {
  font-size: 10px;
  line-height: 1.5;
  text-align: left;
  margin: 5px 0 -1px 0;
}

@media screen and (max-width: 767px) {
  .hero-search-form-row-notes {
    font-size: 10px; font-size: 2.56410vw;
    margin: 5px 0 -1px 0; margin: 1.28205vw 0 -0.25410vw 0;
  }
}



.hero-search-form-controles {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  padding-top: 14px;
}
.hero-search-form-controles button {
  background: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: solid 1px transparent;
  border-radius: 3px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  min-height: 64px;
  min-width: 128px;
  font-size: 16px;
  line-height: 20px;
  -webkit-transition: .3s;
  transition: .3s;
}

#hero-search-form-controle-submit {
  border-color: #AA8919;
  background-color: #AA8919;
  color: #fff;
  width: 254px;
  font-size: 19px;
}
#hero-search-form-controle-submit::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background: url(../../img/icon/icon_sm_search-brown.svg) center no-repeat;
  background-size: contain;
  filter: brightness(0) invert(1);
}
#hero-search-form-controle-submit:hover {
  background-color: #fff;
  color: #AA8919;
}
#hero-search-form-controle-submit:hover::before {
  filter: brightness(1) invert(0);
}

#hero-search-form-controle-more {
  border-color: #00005C;
  background-color: #00005C;
  color: #fff;
}
#hero-search-form-controle-more:hover {
  background-color: #fff;
  color: #00005C;
}

#hero-search-form-controle-reset {
  border-color: #272727;
  background-color: #fff;
}
#hero-search-form-controle-reset:hover {
  background-color: #272727;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .hero-search-form-controles {
    flex-wrap: wrap;
    justify-content: space-between;
    width: 296px; width: 75.89743vw;
    margin: 0 auto;
    gap: 12px; gap: 3.07692vw;
    padding-top: 12px; padding-top: 3.07692vw;
  }
  .hero-search-form-controles li {
    flex-grow: 2;
    flex-basis: calc(50% - 6px); flex-basis: calc(50% - 1.53847vw);
  }
  .hero-search-form-controles li:last-of-type {
    flex-basis: 100%;
    order: 1;
  }
  .hero-search-form-controles li:nth-child(1) {
    order: 2;
  }
  .hero-search-form-controles li:nth-child(2) {
    order: 3;
  }
  
  .hero-search-form-controles button {
    border-radius: 3px; border-radius: 0.76923vw;
    gap: 12px; gap: 1vw;
    min-height: 60px; min-height: 15.38461vw;
    min-width: 0;
    font-size: 14px; font-size: 3.58974vw;
    line-height: 18px; line-height: 4.61538vw;
    width: 100%;
    @include fz-vw(13);
    min-height: auto;
    height: calc(52 / 390 * 100vw);
    @include font-weight(semi);
  }
  #hero-search-form-controle-submit {
    width: 100%;
    font-size: 19px; font-size: 4.87179vw;
    line-height: 24px; line-height: 6.15384vw;
    @include fz-vw(14);
    height: calc(52 / 390 * 100vw);
    min-height: auto;
  }
  #hero-search-form-controle-submit::before {
    width: 24px; width: 6.15384vw;
    height: 24px; height: 6.15384vw;
  }
}
.hero-search-form-input-content {
  height: 0;
  overflow: hidden;
  background-color: #FAFAFA;
}
.active .hero-search-form-input-content {
  height: auto;
  overflow: initial;
}
.hero-search-form-input-content-inner {
  padding: 14px 22px 13px 22px;
  &._type02 {
    padding-bottom: 0;
  }
}
.hero-search-form-input-content-rowgroup {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
.hero-search-form-input-content-colgroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.hero-search-form-input-content-title {
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 5px;
}
.hero-search-form-input-content-title-notes {
  vertical-align: middle;
  display: inline-block;
  font-size: 10px;
  margin: -3px 0 0 4px;
  color: #747474;
}
@media screen and (max-width: 767px) {
  .hero-search-form-input-content-inner {
    padding: 14px; padding: 3.58974vw;
    padding-top: 0;
  }
  .hero-search-form-input-content-rowgroup {
    gap: 10px; gap: 2.56410vw;
  }
  .hero-search-form-input-content-colgroup {
    gap: 10px; gap: 2.56410vw;
  }
  .hero-search-form-input-content-title {
    font-size: 13px; font-size: 3.33333vw;
    margin-bottom: 5px; margin-bottom: 1.28205vw;
  }
  .hero-search-form-input-content-title-notes {
    font-size: 10px; font-size: 2.56410vw;
    margin: -4px 0 0 3px; margin: -1.02564vw 0 0 0.76923vwvw;
  }
}



.hero-search-form-input-content input,
.hero-search-form-input-content select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}
.hero-search-form-input-content input[type="text"],
.hero-search-form-input-content input[type="date"],
.hero-search-form-input-content select {
  display: block;
  width: 100%;
  border: solid 1px #c6c6c6;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
  padding: 9px 12px;
  background: #fff;
  position: relative;
}
.hero-search-form-input-content select::-ms-expand {
  display: none;
}
.hero-search-form-input-content select option:disabled {
  display: none;
}
.hero-search-form-input-content ::placeholder {
  color: #C6C6C6;
}
.hero-search-form-input-content .selector {
  position: relative;
}
.hero-search-form-input-content .selector::before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-color: #000;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 3px;
  z-index: 1;
  margin: auto 0;
}

@media screen and (max-width: 767px) {
  .hero-search-form-input-content input[type="text"],
  .hero-search-form-input-content input[type="date"],
  .hero-search-form-input-content select {
    border-radius: 3px; border-radius: 0.76923vw;
    font-size: 12px; font-size: 3.07692vw;
    line-height: 1.5;
    padding: 9px 12px; padding: 2.30769vw 3.07692vw;
  }
  .hero-search-form-input-content .selector::before {
    width: 6px; width: 1.53846vw;
    height: 6px; height: 1.53846vw;
    right: 14px; right: 3.58974vw;
    bottom: 3px; bottom: 0.76923vw;
  }
}

.search-modal-accordion-header {
  cursor: pointer;
}
.search-modal-accordion-header label {
  pointer-events: none;
}
.search-modal-accordion-header label::before,
.search-modal-accordion-header label::after {
  pointer-events: all;
}




#hero-search-map {
  position: relative;
  background: url(../../img/top/pc-hero-search-map.png) center no-repeat;
  background-size: contain;
  width: 602px;
  height: 330px;
  margin: 0px -22px -13px -22px;
}
#hero-search-map-title {
  font-size: 16px;
  line-height: 1.5;
  color: #00005C;
  text-align: left;
  margin: 1px 0 8px -22px;
}
#hero-search-map-title span {
  font-size: 10px;
  color: #747474;
}
.hero-search-map-point {
  position: absolute;
  z-index: 1;
}
.hero-search-map-point > input {
  display: none;
}
.hero-search-map-point > label {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  line-height: 22px;
  text-align: left;
  z-index: 1;
  span {
    font-weight: 600;
  }
  .fs-small {
    font-size: 1rem;
    @include sp {
      @include fz-vw(8);
    }
  }
}
.hero-search-map-point > label::before {
  content: "";
  display: block;
  border-radius: 50%;
  background-color: rgba(90,90,90,0.1);
  position: absolute;
  z-index: 1;
  -webkit-transition: .3s;
  transition: .3s;
}
.hero-search-map-point > label:hover::before,
.hero-search-map-point > input:checked+label::before {
  background-color: rgba(170,137,25,0.8);
}
.hero-search-map-point > label > span {
  position: relative;
  z-index: 2;
  display: inline-block;
  padding-left: 24px;
  -webkit-transition: .3s;
  transition: .3s;
  text-shadow:
    -1px -1px 0px #fff, -1px 0px 0px #fff, -1px 1px 0px #fff,
    0px -1px 0px #fff, 0px 0px 0px #fff, 0px 1px 0px #fff,
    1px -1px 0px #fff, 1px 0px 0px #fff, 1px 1px 0px #fff;
}
.hero-search-map-point > label:hover > span,
.hero-search-map-point > input:checked+label > span {
  color: #fff;
  text-shadow:
    -1px -1px 0px #AA8919, -1px 0px 0px #AA8919, -1px 1px 0px #AA8919,
    0px -1px 0px #AA8919, 0px 0px 0px #AA8919, 0px 1px 0px #AA8919,
    1px -1px 0px #AA8919, 1px 0px 0px #AA8919, 1px 1px 0px #AA8919;
}
.hero-search-map-point > label > span::before,
.hero-search-map-point > label > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.hero-search-map-point > label > span::before {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: solid 1px #C6C6C6;
  background-color: #fff;
  left: 0;
  -webkit-transition: .3s;
  transition: .3s;
}
.hero-search-map-point > label > span::after {
  width: 9px;
  height: 5px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 0 2px 2px;
  transform: rotate(-45deg);
  top: -4px;
  left: 3px;
  opacity: 0;
  -webkit-transition: .3s;
  transition: .3s;
}
.hero-search-map-point > input:checked+label > span::before {
  background-color: #00005C;
  border-color: #00005C;
}
.hero-search-map-point > input:checked+label > span::after {
  opacity: 1;
}
#hero-search-map-point-01 {
  top: 20px;
  left: 43px;
}
#hero-search-map-point-02 {
  top: 75px;
  left: 460px;
}
#hero-search-map-point-03 {
  top: 45px;
  left: 234px;
}
#hero-search-map-point-04 {
  top: 270px;
  left: 56px;
}
#hero-search-map-point-05 {
  top: 170px;
  left: 185px;
}
#hero-search-map-point-06 {
  top: 240px;
  left: 372px;
}
#hero-search-map-point-07 {
  top: 295px;
  left: 215px;
}
#hero-search-map-point-08 {
  top: 242px;
  left: 497px;
}

#hero-search-map-point-01 > label::before {
  width: 160px;
  height: 160px;
  top: -17px;
  left: -19px;
}
#hero-search-map-point-02 > label::before {
  width: 140px;
  height: 140px;
  top: -32px;
  left: -90px;
}
#hero-search-map-point-03 > label::before {
  width: 120px;
  height: 120px;
  top: -15px;
  left: -18px;
}
#hero-search-map-point-04 > label::before {
  width: 120px;
  height: 120px;
  top: -84px;
  left: -25px;
}
#hero-search-map-point-05 > label::before {
  width: 100px;
  height: 100px;
  top: -35px;
  left: -45px;
}
#hero-search-map-point-06 > label::before {
  width: 100px;
  height: 100px;
  top: -56px;
  left: -13px;
}
#hero-search-map-point-07 > label::before {
  width: 120px;
  height: 120px;
  top: -95px;
  left: 20px;
}
#hero-search-map-point-08 > label::before {
  width: 100px;
  height: 100px;
  top: -25px;
  left: -8px;
}

@media screen and (max-width: 767px) {
  #hero-search-map {
    background-image: url(../../img/top/sp-hero-search-map.png);
    background-size: cover;
    width: calc(100% + 28px); width: calc(100% + 7.17948vw);
    height: 382px; height: 97.94871vw;
    margin: 0 -14px -14px -14px -14px; margin: 0 -3.58974vw -3.58974vw -3.58974vw;
    overflow: hidden;
  }
  #hero-search-map-title {
    font-size: 14px; font-size: 3.58974vw;
    margin: 40px -14px 12px -14px; margin: 10.25641vw -3.58974vw 3.07692vw -3.58974vw;
    padding-left: var(--size-15);
    margin-top: var(--size-30);
  }
  #hero-search-map-title span {
    display: block;
    font-size: 2.56410vw;
    margin-top: -2px; margin-top: -0.51282vw;
  }

  .hero-search-map-point > label {
    font-size: 10px; font-size: 2.56410vw;
    line-height: 1.3;
    min-height: 16px; min-height: 4.10256vw;
  }
  .hero-search-map-point > label > span {
    padding-left: 20px; padding-left: 5.12820vw;
  }
  .hero-search-map-point > label > span::before {
    width: 14px; width: 3.58974vw;
    height: 14px; height: 3.58974vw;
    border-radius: 3px; border-radius: 0.76923vw;
  }
  .hero-search-map-point > label > span::after {
    width: 7px; width: 1.79487vw;
    height: 4px; height: 1.02564vw;
    border-width: 0 0 2px 2px; border-width: 0 0 0.51282vw 0.51282vw;
    transform: rotate(-45deg);
    top: -3px; top: -0.76923vw;
    left: 3px; left: 0.76923vw;
  }

  #hero-search-map-point-01 {
    top: 73px; top: 18.71794vw;
    left: 16px; left: 4.10256vw;
  }
  #hero-search-map-point-02 {
    top: 61px; top: 15.64102vw;
    left: 262px; left: 67.17948vw;
  }
  #hero-search-map-point-03 {
    top: 89px; top: 22.82051vw;
    left: 120px; left: 30.76923vw;
  }
  #hero-search-map-point-04 {
    top: 284px; top: 72.82051vw;
    left: 17px; left: 4.35897vw;
  }
  #hero-search-map-point-05 {
    top: 203px; top: 52.05128vw;
    left: 128px; left: 32.82051vw;
  }
  #hero-search-map-point-06 {
    top: 276px; top: 70.76923vw;
    left: 235px; left: 60.25641vw;
  }
  #hero-search-map-point-07 {
    top: 342px; top: 87.69230vw;
    left: 120px; left: 30.76923vw;
  }
  #hero-search-map-point-08 {
    top: 340px; top: 87.17948vw;
    left: 270px; left: 69.23076vw;
  }

  #hero-search-map-point-01 > label::before {
    width: 136px; width: 34.87179vw;
    height: 136px; height: 34.87179vw;
    top: -16px; top: -4.10256vw;
    left: -46px; left: -11.79487vw;
  }
  #hero-search-map-point-02 > label::before {
    width: 155px; width: 39.74358vw;
    height: 155px; height: 39.74358vw;
    top: -7px; top: -1.79487vw;
    left: -40px; left: -10.25641vw;
  }
  #hero-search-map-point-03 > label::before {
    width: 98px; width: 25.12820vw;
    height: 98px; height: 25.12820vw;
    top: -16px; top: -4.10256vw;
    left: -4px; left: -1.02564vw;
  }
  #hero-search-map-point-04 > label::before {
    width: 98px; width: 25.12820vw;
    height: 98px; height: 25.12820vw;
    top: -65px; top: -16.66666vw;
    left: -24px; left: -6.15384vw;
  }
  #hero-search-map-point-05 > label::before {
    width: 98px; width: 25.12820vw;
    height: 98px; height: 25.12820vw;
    top: -37px; top: -9.48717vw;
    left: -49px; left: -12.56410vw;
  }
  #hero-search-map-point-06 > label::before {
    width: 98px; width: 25.12820vw;
    height: 98px; height: 25.12820vw;
    top: -67px; top: -17.17948vw;
    left: -28px; left: -7.17948vw;
  }
  #hero-search-map-point-07 > label::before {
    width: 114px; width: 29.23076vw;
    height: 114px; height: 29.23076vw;
    top: -67px; top: -17.17948vw;
    left: -7px; left: -1.79487vw;
  }
  #hero-search-map-point-08 > label::before {
    width: 90px; width: 23.07692vw;
    height: 90px; height: 23.07692vw;
    top: -38px; top: -9.74358vw;
    left: -10px; left: -2.56410vw;
  }
}



.layout-check-list {
  display: flex;
  gap: 8px;
  padding-bottom: 2px;
}
.layout-check-list li {
  flex-basis: 86px;
}
.layout-check-list li input {
  display: none;
}
.layout-check-list li label {
  display: block;
  border: solid 3px #fff;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.3);
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  -webkit-transition: .3s;
  transition: .3s;
  display: flex;
  flex-flow: column-reverse;
  height: var(--size-110);
  box-sizing: border-box;
  justify-content: space-between;
  @include sp {
    height: var(--size-115);
  }
  .c_text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include font-size(12);
    @include line-height(15, 12);
    height: var(--size-45);
    text-align: left;
    position: relative;
    padding-left: 7px;
    @include sp {
      @include fz-vw(12);
      padding-left: var(--size-10);
    }
  }
}
.layout-check-list li label > img {
  width: 100%;
  height: auto;
  // max-width: 64px;
  // margin: 0 10px;
}
.layout-check-list li label > span,
.layout-check-list li label .c_text span {
  position: relative;
  display: block;
  padding: 5px 0 5px 31px;
  font-size: 10px;
  line-height: 1.6;
  text-align: left;
  -webkit-transition: .3s;
  transition: .3s;
  width: 16px;
  position: initial;
  @include sp {
    width: calc(16 / 390 * 100vw);
  }
}
.layout-check-list li label .c_text span {
  padding: 0;
  margin-right: var(--size-5);
  display: block;
  position: relative;
}
.layout-check-list li label > span::before,
.layout-check-list li label > span::after,
.layout-check-list li label .c_text span::before,
.layout-check-list li label .c_text span::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  -webkit-transition: .3s;
  transition: .3s;
}
.layout-check-list li label > span::before,
.layout-check-list li label .c_text span::before {
  // left: 11px;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border: solid 1px #C6C6C6;
  border-radius: 3px;
  @include sp {
  width: calc(14 / 390 * 100vw);
  height: calc(14 / 390 * 100vw);
  }

}
.layout-check-list li label > span::after,
.layout-check-list li label .c_text span::after  {
  top: -2px;
  left: 4px;
  width: 8px;
  height: 4px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 0 2px 2px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  opacity: 0;
  @include sp {
    top: calc(-2 / 390 * 100vw);
    left: calc(3.8 / 390 * 100vw);
    width: calc(8 / 390 * 100vw);
    height: calc(4 / 390 * 100vw);
  }
}
.layout-check-list li label:hover,
.layout-check-list li input:checked+label {
  border-color: #00005C;
  .c_text {
    background: #F0F0F0;
  }
}
.layout-check-list li label:hover > span,
.layout-check-list li input:checked+label > span,
.layout-check-list li label:hover .c_text span,
.layout-check-list li input:checked+label .c_text span
 {
  background-color: #F0F0F0;
}
.layout-check-list li input:checked+label > span::before,
.layout-check-list li input:checked+label .c_text span::before {
  background-color: #00005C;
  border-color: #00005C;
}
.layout-check-list li input:checked+label > span::after,
.layout-check-list li input:checked+label .c_text span::after {
  opacity: 1;
}
.layout-check-list li input:disabled+label {
  opacity: 0.4;
  pointer-events: none;
}

@media screen and (max-width: 767px) {
  .layout-check-list {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 10px 12px; gap: 2.56410vw;
    padding-bottom: 2px; padding-bottom: 0.51282vw;
  }
  .layout-check-list li label {
    border-radius: 3px; border-radius: 0.76923vw;
  }
  .layout-check-list li label > img {
    max-width: 100%;
    margin: 0;
  }
  .layout-check-list li label > span {
    padding: 5px 0 5px 31px; padding: 1.28205vw 0 1.28205vw 7.94871vw;
    font-size: 10px; font-size: 2.56410vw;
  }
  .layout-check-list li label > span::before {
    left: 11px; left: 2.82051vw;
    width: 14px; width: 3.58974vw;
    height: 14px; height: 3.58974vw;
    border-radius: 3px; border-radius: 0.76923vw;
  }
  .layout-check-list li label > span::after {
    top: -2px; top: -0.51282vw;
    left: 14px; left: 3.58974vw;
    width: 8px; width: 2.05128vw;
    height: 4px; height: 1.02564vw;
    border-width: 0 0 2px 2px; border-width: 0 0 0.51282vw 0.51282vw;
  }
}



#input-day {
  margin-right: 10px;
  width: 170px;
}
#input-start-time,
#input-end-time {
  width: 150px;
}

@media screen and (max-width: 767px) {
  #input-group-day-time {
    flex-wrap: wrap;
    gap: 6px 8px;
  }
  #input-group-day-time :first-child {
    width: 100%;
  }
  #input-day {
    width: 100%;
    margin-right: 0;
  }
  #input-group-day-time .selector {
    flex-grow: 2;
  }
}






#hero-search-form-input-ex01.active {
  overflow: visible;
  border-color: #C6C6C6;
}
#hero-search-form-input-ex01.active .hero-search-form-input-content {
  background-color: rgba(255,255,255,0.9);
  margin: -88px -1px -1px -1px;
  @include sp {
    background-color: rgba(255,255,255,1);
  }
}
#hero-search-form-input-ex01.active .hero-search-form-input-switcher .hero-search-form-input-switcher-closer {
  opacity: 0;
}
#hero-search-form-input-ex01 .hero-search-form-input-content .hero-search-form-input-switcher-closer {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  box-sizing: border-box;
  background: none;
  margin: 0;
  padding: 0;
  border: solid 1px #00005C;
  border-radius: 50%;
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -8px;
  right: -52px;
  opacity: 0;
  pointer-events: none;
  -webkit-tap-highlight-color: transparent;
}
#hero-search-form-input-ex01.active .hero-search-form-input-content .hero-search-form-input-switcher-closer {
  opacity: 1;
  pointer-events: all;
}
#hero-search-form-input-ex01 .hero-search-form-input-content .hero-search-form-input-switcher-closer::before,
#hero-search-form-input-ex01 .hero-search-form-input-content .hero-search-form-input-switcher-closer::after {
  content: "";
  display: block;
  width: 60%;
  height: 1px;
  background-color: #00005C;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
#hero-search-form-input-ex01 .hero-search-form-input-content .hero-search-form-input-switcher-closer::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
#hero-search-form-input-ex01 .hero-search-form-input-content .hero-search-form-input-switcher-closer::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media screen and (max-width: 767px) {
  #hero-search-form-input-ex01.active .hero-search-form-input-switcher {
    z-index: 1;
  }
  #hero-search-form-input-ex01.active .hero-search-form-input-content {
    margin: -80px -1px 0 -1px; margin: -20.51282vw -0.25641vw 0 -0.25641vw;
    width: calc(100% + 2px);
    position: absolute;
    z-index: 2;
    left: calc(-20 / 390 * 100vw);
    padding-left: var(--size-20);
    padding-right: var(--size-20);
  }
  #hero-search-form-input-ex01 .hero-search-form-input-content .hero-search-form-input-switcher-closer {
    width: 50px; width: 12.82051vw;
    width: var(--size-30);
    height: var(--size-30);
    top: 20px; top: 5.12820vw;
    right: 0;
  }
}

.p-area_closeBtn_container {
  display: flex;
  margin-top: 21px;
  justify-content: center;
  @include sp {
    margin-top: calc(25 / 390 * 100vw);
    padding-bottom: calc(27 / 390 * 100vw);
  }
  .c-btn {
    font-size: 1.6rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    @include sp {
      font-size: calc(13 / 390 * 100vw);
    }
    &._back {
      width: 98px;
      height: 44px;
      border: 1px solid #272727;
      transition: 0.3s;
      @include sp {
        width:calc(88 / 390 * 100vw);
        height:calc(44 / 390 * 100vw);
      }
      &:hover {
        background: #272727;
        color: #FFF;
      }
    }
    &._select {
      width: 196px;
      height: 44px;
      background: #00005C;
      color: #FFF;
      margin-left: 14px;
      transition: 0.3s;
      @include sp {
        width:calc(175 / 390 * 100vw);
        height:calc(44 / 390 * 100vw);
      }
      &:hover {
        color: #00005C;
        background: #FFF;
        border: 1px solid #00005C;
      }
    }
  }
}
